/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex";

.cardAuth{
  max-width: 500px;
  width: 100%;
}

.cardAuthCenter{
  display: flex;
  justify-content: center;
}

html{
  font-size: medium;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

body {
  background-color: rgb(250, 250, 250);
}

.p-button.p-button-info {
  height: 35px;
}

.p-button.p-button-success {
  height: 35px;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
  height: 32px;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
  height: 32px;
}
.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
  height: 32px;
}

@media screen and (min-width: 850px) {
  .cardAuth{
    max-width: 500px;
    width: 100%;
  }
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  // margin-left: 30%;
}

// .p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list {
//   display: block;
//   right: 100%;
//   top: 0;
// }

.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list {
  left: -150px !important;
  max-width: 260px !important;
}

@media screen and (max-width: 767px) {
  .p-dialog .p-dialog-content {
    padding: 0 0 2rem 0;
  }
}

.fixedHeader{
  position: sticky;
    top:0;
    left: 0;
    width: 100%;
    z-index: 1101 !important;
    background-color: white;
}

.p-dialog .p-dialog-footer {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

// ##### Cores #####
.radio-color .p-radiobutton .p-radiobutton-box {
    width: 20px !important;
    height: 20px !important;
    border: none;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
    background: transparent;
}

.radio-color .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: none;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
    background: transparent;
}

.radio-color .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: none;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.445);
    background: transparent;
}

.radio-color .p-radiobutton .p-radiobutton-box:not(p.disabled).p-focus {
  box-shadow: none;
}
.radio-black .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color:  rgb(26, 26, 26);
}

// ########

.strike-through {
  text-decoration: line-through !important;
}

.font-small {
  font-size: 12px;
}

.font-normal {
  font-size: 18px;
}
